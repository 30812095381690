import React from "react";
import styles from "./EventDescription.module.css";
import Markdown from "markdown-to-jsx";
const EventDescription = ({ description }) => {
  function formatMarkdown(markdown) {
    var modifiedMarkdown = markdown
      .replace(/\* /g, "\n* ")
      .replace(/body/g, " ")
      .replace(/script/g, " ")
      .replace(/html/g, " ");
    return modifiedMarkdown;
  }
  console.log(description);
  
  const formattedMarkdown = formatMarkdown(description);
  return (
    <div className={styles.description_wrap}>
      <h2>DESCRIPTION</h2>
      <Markdown>{formattedMarkdown}</Markdown>
    </div>
  );
};

export default EventDescription;
