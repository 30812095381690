"use client";
import styles from "./Homepage.module.css";
import EventCard from "@components/EventCard/EventCard";
import MainEvent from "@components/MainEvent/MainEvent";
import { groupEvents } from "@helpers/groupEvents";
import { useState, useEffect, useRef } from "react";
import Navbar from "@layout/Navbar/Navbar";
import SearchBar from "@components/Searchbar/SearchBar";
import CountryList from "@components/CountryList/CountryList";
import {
  getAllEvents,
  getBook,
  getEvents,
  getEventsLocation,
} from "@services/eventAPI";
import CustomLoading from "@components/UI/CustomLoading/CustomLoading";
import Loading from "@app/[country]/loading";
import Footer from "@layout/Footer/Footer";
// import Adjust from "@adjustcom/adjust-web-sdk";
import config from "@config/config";
// import ReactGA from "react-ga4";
import { object } from "yup";
import { useUser } from "@hooks/Auth/useUser";
import Link from "next/link";


// import { BiSolidDownArrowCircle } from "react-icons/bi";
const Homepage = ({
  // events = [],
  // mainEvents,
  isCountry,
  // everyEvents = [],
  // countries = [],
}) => {
  const [events, setEvents] = useState([]);
  const [mainEvents, setMainEvents] = useState();
  const [mainOrgs, setMainOrgs] = useState();
  const [countries, setCountries] = useState();
  const [everyEvents, setEveryEvents] = useState([]);
  let { user } = useUser();
console.log(user);

  // ReactGA.initialize("G-3CDD4SNQ69");
  // {
  //   isCountry &&
  //     ReactGA.send({ hitType: "pageView", page: "/", title: `${countries}` });
  // }
  // {
  //   !isCountry &&
  //     ReactGA.send({ hitType: "pageView", page: "/", title: "Lebanon" });
  // }
  // function adjustSet() {
  //   Adjust.initSdk({
  //     appToken: config.ADJUST_TOKEN,
  //     environment: "sandbox",
  //     externalDeviceId: "3DFD7DD1-D131-4880-A57D-DB9798F635EF",
  //     logLevel: "verbose",
  //   });
  // }

  // useEffect(() => {
  //   adjustSet();
  // }, []);

  function replaceEncodedSpaces(str) {
    return str.replace(/%20/g, ' ');
}
  const [loadingTry, setLoadingTry] = useState(true);
  const getEvent = async () => {
    setLoadingTry(true);
    const co = await getEventsLocation();
    setCountries(co);
    const ev = await getEvents("country", isCountry);
    setEvents(ev);
    const isC = replaceEncodedSpaces(isCountry)
    const bo = await getBook(isC);
    const events = bo;
    const orgs = bo.orgs;
    console.log(orgs);
    setMainEvents(events);
    setMainOrgs(orgs);
    const aev = await getAllEvents();
    setEveryEvents(aev);
    setLoadingTry(false);
  };
  useEffect(() => {
    getEvent();
  }, []);

  const allEvents = events;
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchEvents, setSearchEvents] = useState(allEvents);

  const [visibleEvents, setVisibleEvents] = useState(events.slice(0, 5));
  const loadingRef = useRef(null);
  // Function to load more events
  const loadMore = () => {
    const moreEvents = events.slice(
      visibleEvents.length,
      visibleEvents.length + 5
    ); // Load 10 more events
    setVisibleEvents((prevEvents) => [...prevEvents, ...moreEvents]);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          loadMore(); // Load more events when the last element becomes visible
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      }
    );

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    return () => {
      if (loadingRef.current) {
        observer.unobserve(loadingRef.current);
      }
    };
  }, [events, visibleEvents]);

  useEffect(() => {
    const filteredEvents = visibleEvents.filter((event) =>
      event.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchEvents(filteredEvents);
  }, [searchTerm, visibleEvents]);

  const { groups, labels } = groupEvents(searchEvents, "day");

  let sortedKeys = Object.keys(groups).filter((key) => key !== "ongoing");
  sortedKeys = sortedKeys.sort((a, b) => Number(a) - Number(b));

  if (groups["ongoing"]) {
    sortedKeys.unshift("ongoing");
  }
  const getDisplayName = (country) => {
    return country === "United Arab Emirates" ? "UAE" : country;
  };
  const [uniqueEventCountries, setUniqueEventCountries] = useState([]);

  useEffect(() => {
    if (allEvents.length > 0) {
      const eventCountries = allEvents.map((event) => event.eventCountry);
      const uniqueEventCountriesSet = Array.from(new Set(eventCountries));
      setUniqueEventCountries(uniqueEventCountriesSet);
    }
  }, [allEvents]);

  if (!allEvents.length) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <>
      {/* <div className={styles.appReturn}>
        for better experience{" "}
        <Link
          href={"link.tickit.co/2d3"}
          // onClick={() => {
          //   const newLink = dynamicUrl.shortLink;
          //   router.replace(newLink);
          // }}
        >
          Open App
        </Link>
      </div> */}
      <Navbar
        onSearchIconClick={() => setIsSearchBarVisible(true)}
        allEvents={everyEvents?.length > 0 ? everyEvents : events}
      />
      {isCountry && (
        <header className={styles.header}>
          <h1>Events in {countries && <CountryList list={countries} />}</h1>
          <span className={styles.dropdown}>
            {/* <BiSolidDownArrowCircle /> */}
          </span>
          <span className={styles.headspan}>|</span>
          <span className={styles.headspan}>{events.length} Events</span>
        </header>
      )}
      <SearchBar
        events={events}
        open={isSearchBarVisible}
        onClose={() => setIsSearchBarVisible(false)}
      />
      <div className={styles.homepage}>
        <MainEvent mainEvents={mainEvents} mainOrgs={mainOrgs} />

       
        {sortedKeys.map((key) => (
          <div key={key}>
            <h2 className={`${styles.date}`}>{labels[key]}</h2>
            <div className={styles.event}>
              {groups[key].map((event) => {
                if (event.eventID !== "eHaECJSmHrBAs7ht3WoR")
                  return <EventCard event={event} key={event.eventID} />;
              })}
            </div>
          </div>
        ))}
        <div ref={loadingRef}></div>
      </div>
      
      <Footer />
    </>
  );
};

export default Homepage;
